// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-beer-js": () => import("/codebuild/output/src368756239/src/src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src368756239/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src368756239/src/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acs-js": () => import("/codebuild/output/src368756239/src/src/pages/acs.js" /* webpackChunkName: "component---src-pages-acs-js" */),
  "component---src-pages-beers-js": () => import("/codebuild/output/src368756239/src/src/pages/beers.js" /* webpackChunkName: "component---src-pages-beers-js" */),
  "component---src-pages-blog-js": () => import("/codebuild/output/src368756239/src/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src368756239/src/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src368756239/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

